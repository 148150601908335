<template>
  <base-material-card
    flat
    :showsheet="false"
  >
    <v-card-text>
      <v-subheader class="wee-text-muted">{{$t('base.changePassword')}}</v-subheader>
      <validation-observer
        ref="form"
        v-slot="{ handleSubmit, reset }"
      >
        <form
          @submit.prevent="handleSubmit(onSave)"
          @reset.prevent="reset"
        >
          <v-container>
            <v-row>

              <v-col
                cols="12"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="currentPassword"
                  rules="required|min:8"
                >
                  <v-text-field
                    prepend-icon="mdi-shield-key"
                    v-model="entity.currentPassword"
                    :error-messages="errors"
                    :placeholder="$t('authen.currentPassword')"
                    :label="$t('authen.currentPassword')"
                    :append-icon="
                        showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                    :type="showPwd ? 'text' : 'password'"
                    @click:append="showPwd = !showPwd"
                    autocomplete="off"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|min:8|password:@confirm"
                >
                  <v-text-field
                    prepend-icon="mdi-shield-key"
                    v-model="entity.password"
                    :error-messages="errors"
                    :placeholder="$t('authen.password')"
                    :label="$t('authen.password')"
                    :append-icon="
                        showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                    :type="showPwd ? 'text' : 'password'"
                    @click:append="showPwd = !showPwd"
                    autocomplete="off"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="confirm"
                  rules="required|min:8|password:@password"
                >
                  <v-text-field
                    prepend-icon="mdi-shield-key"
                    :append-icon="
                        showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                    :type="showPwd ? 'text' : 'password'"
                    v-model="entity.repassword"
                    :error-messages="errors"
                    :placeholder="$t('authen.confirmPassword')"
                    :label="$t('authen.confirmPassword')"
                    @click:append="showPwd = !showPwd"
                    autocomplete="off"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-switch
                  v-model="entity.logoutAll"
                  :label="$t('authen.logoutAll')"
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                class="mt-6"
                align="center"
              >
                <v-btn
                  text
                  @click="clear"
                  :disabled="processing"
                >
                  <v-icon class="mr-2">mdi-eraser</v-icon>
                  {{ $t("base.clear") }}
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  text
                  :disabled="processing"
                  :loading="processing"
                >
                  <v-icon class="mr-2">mdi-lead-pencil</v-icon> {{ $t("base.changePassword") }}
                </v-btn>
              </v-col>

            </v-row>
          </v-container>

        </form>
      </validation-observer>
    </v-card-text>
  </base-material-card>
</template>
<script>
import { defineComponent, reactive, ref } from "@vue/composition-api";
import { hashSha512 } from "@/plugins/util";
export default defineComponent({
  props: {
    value: Object,
    processing: {
      type: Boolean,
      Default: false
    }
  },
  setup(props, { refs, emit }) {
    const showPwd = ref(false);
    const entity = reactive({
      currentPassword: "",
      password: "",
      repassword: "",
      logoutAll: true
    });
    const onSave = async () => {
      const currentPwd = await hashSha512(entity.currentPassword);
      const password = await hashSha512(entity.password);
      emit("save", {
        oldPassword: currentPwd,
        _p: password,
        logoutAll: entity.logoutAll
      });
    };
    const clear = () => {
      refs.form.reset();
      entity.currentPassword = "";
      entity.password = "";
      entity.repassword = "";
      entity.logoutAll = true;
    };
    return {
      entity,
      showPwd,
      clear,
      onSave
    };
  }
});
</script>